/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import '../node_modules/ngx-spinner/animations/ball-clip-rotate-multiple.css';

.nav-sys-btn {
   font-weight: 550;
}
.nav-super-btn {
   font-weight: bold;
   font-style: italic;
}
.nav-selected {
   font-weight: 550;
   text-decoration: underline;
}

.card {
   margin: 20px;
}

.form-control {
   font-weight: 550;
}
.bolder {
   font-weight: 550;
}
.custom-select {
   font-weight: 550;
}
option {
   font-weight: 550;
}

.form-control.ng-invalid.ng-touched {
   border: 2px solid red;
}
.form-control.ng-invalid.ng-dirty {
   border: 2px solid red;
}

.navbar {
   z-index: 3;
}
bs-dropdown-container {
   z-index: 4;
}

ul.pagination {
   margin-bottom: 0;
}

[type='date']::-webkit-inner-spin-button {
   display: none;
}
[type='date']::-webkit-calendar-picker-indicator {
   display: none;
}

.tooltip {
   padding: 0rem;
}

html {
   font-size: 0.9rem;
   padding-bottom: 2.5rem;
}

@media (min-width: 1300px) {
   .navbar-expand-custom {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
   }
   .navbar-expand-custom .navbar-nav {
      flex-direction: row;
   }
   .navbar-expand-custom .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
   }
   .navbar-expand-custom .navbar-collapse {
      display: flex !important;
   }
   .navbar-expand-custom .navbar-toggler {
      display: none;
   }
}

.container {
   white-space: pre-line !important;
}
